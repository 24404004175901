import { ContentLayout } from "../../layout/ContentLayout";
import pixel from "../../../../assets/images/pixeldash/pixel.png";
import ipad from "../../../../assets/images/pixeldash/iPadScreenshot.png";
import pixel1 from "../../../../assets/images/pixeldash/1.gif";
import pixel2 from "../../../../assets/images/pixeldash/2.gif";
import pixel3 from "../../../../assets/images/pixeldash/3.gif";
import pixel4 from "../../../../assets/images/pixeldash/4.gif";
import pixel5 from "../../../../assets/images/pixeldash/5.gif";
import pixel6 from "../../../../assets/images/pixeldash/6.gif";

export const PixelDash_Overview = () => {
  return (
    <>
      <ContentLayout useBackground={true}>
        <h1 className="display-4">PixelDash Pixel animator</h1>
        <p className="lead">
          The pixel dash is a pixel animator is fun creator tool that I have built in a number of programming languages
          over the last few years.
        </p>
        <hr className="my-4" />
        <p>
          The basic idea is a creator tool that allows the user to build frame by frame pixel animations. The data is
          stored data in a JSON file. A renderer then reads the JSON file and renders the data to a canvas element.
        </p>

        <div className="text-center">
          <figure className="figure">
            <img src={pixel} alt="With CSS overlay" className="rounded mx-auto d-block img-fluid" />
            <figcaption className="figure-caption text-right">With CSS overlay</figcaption>
          </figure>
          <figure className="figure">
            <img src={ipad} alt="ipad selection screen" className="rounded mx-auto d-block img-fluid" />
            <figcaption className="figure-caption text-right">iPad Selection screen</figcaption>
          </figure>
        </div>

        <h2>Animated Saves from the iPad version</h2>
        <div className="text-center">
          <figure className="figure">
            <img src={pixel1} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
          <figure className="figure">
            <img src={pixel2} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
          <figure className="figure">
            <img src={pixel3} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
          <figure className="figure">
            <img src={pixel4} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
          <figure className="figure">
            <img src={pixel5} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
          <figure className="figure">
            <img src={pixel6} alt="animation" className="rounded mx-auto d-block img-fluid" />
          </figure>
        </div>
      </ContentLayout>
    </>
  );
};
