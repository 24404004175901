import { BootswatchSelect } from "react-bootswatch-select";
import { Outlet } from "react-router-dom";
import Header from "../../header/Header";

export const Layout = () => {
  return (
    <>
      <BootswatchSelect version={"4.4.1"} className="form-control" selectedThemeName="darkly" selectorHidden />
      <Header />
      <Outlet />
    </>
  );
};
