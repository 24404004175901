import { ContentLayout } from "../../layout/ContentLayout";

export const HackManchester2019_Overview = () => {
  return (
    <>
      <ContentLayout useBackground={true}>
        <h1 className="display-4">Hack Manchester 2019</h1>
        <p className="lead">
          <a href="https://www.hackmanchester.com/" target={"_blank"}>
            Hack Manchester
          </a>{" "}
          was a 24 hour coding competition were Teams of up to 4 competed. Teams could choose one of the predefined
          challenges, they were then tasked to implement their solution over the 24 hour event. The teams were judged on
          production of a final 2 minute video at the end. We chose the Clockwork SMS challenge.
        </p>
        <h2>The clockwork sms challenge</h2>
        <p>
          <blockquote>
            9.8% of adults in Greater Manchester have a long-term health condition or disability that significantly
            impairs their everyday lives (NHS Manchester). Using our incredibly easy API, find a way that SMS can be
            used to improve the lives of people suffering with such conditions.
          </blockquote>
        </p>

        <p>
          From this brief we came up with "Everyday Matters" - an idea to build an early intervention system to identify
          people with mental issues. We built the following system:
        </p>
        <ul>
          <li>A scheduled lambda to send an SMS to a user once per day to check in on them </li>
          <li>
            Push SMS responses through a custom .net sentiment analyser to categorise and store mood. We used a scale of
            anger, disgust, fear, happy, neutral, sad, suprised
          </li>
          <li>A realtime mood board to display user mood levels, e.g. anger(3), disgust(1), happy(8), etc.</li>
          <li>
            Amazon Lex would be used to provide programmed intents (Lex is a service that allows you to build
            conversational interfaces into any application using voice and text.). This would allow automated
            conversations to take place, for example, suggesting local helplines or groups
          </li>
        </ul>
        <ul>
          <li> A scheduled Amazon Lex service that would communicate with a person -</li>
          <li>Custom built sentiment analyzer to determine a persons mood and respond with the </li>
          <li>Trained model of words, sentiment to mood </li>
        </ul>
        <a href="https://www.youtube.com/watch?v=ZWW-zcd2mK4" target={"_blank"}>
          Watch our team interview video
        </a>
        <p></p>
      </ContentLayout>
    </>
  );
};
