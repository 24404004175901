import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { CV } from "./components/pages/cv/CV";

import { Home } from "./components/pages/home/Home";
import { Layout } from "./components/pages/layout/Layout";
import { NoPage } from "./components/pages/nopage/NoPage";
import Now from "./components/pages/now/Now";
import { HackManchester2019_Overview } from "./components/pages/projects/hackmanchester2019/HackManchester2019_Overview";
import { PixelDash_Overview } from "./components/pages/projects/pixeldash/PixelDash_Overview";
import { PiEnergyMonitor } from "./components/pages/projects/raspberrypienergymonitor/PiEnergyMonitor";
import { PiEnergyMonitor_Overview } from "./components/pages/projects/raspberrypienergymonitor/PiEnergyMonitor_Overview";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="now" element={<Now />} />
            <Route path="cv" element={<CV />} />
            <Route path="pi_energy_monitor_overview" element={<PiEnergyMonitor_Overview />} />
            <Route path="pi_energy_monitor" element={<PiEnergyMonitor />} />
            <Route path="pixel_overview" element={<PixelDash_Overview />} />
            <Route path="HackManchester2019_Overview" element={<HackManchester2019_Overview />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
