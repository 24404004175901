import familyPhoto from "../../../assets/images/family.jpg";

import Row from "react-bootstrap/Row";
import { ContentLayout } from "../layout/ContentLayout";
export const Home = () => {
  return (
    <>
      <ContentLayout>
        <div className="card mb-3" style={{ maxWidth: "1400px" }}>
          <Row className="g-0">
            {/* <Col md={4} style={{ display: "flex" }}> */}
            <div className="col-md-4 " style={{ display: "flex" }}>
              <img
                src={familyPhoto}
                className="img-fluid rounded-start "
                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "cover" }}
                alt="diggle family"
              />
            </div>
            {/* </Col> */}
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">Summary</h5>
                <p className="card-text">
                  <ul>
                    <li>located in the Bolton, UK</li>
                    <li>developer since 1996, currently at AO</li>
                    <li>married with two teenage kids</li>
                    <li>being active outdoors, hiking, cycling, camping</li>
                    <li>latest personal project - Raspberry PI Energy Monitor</li>
                  </ul>
                </p>
                <p className="card-text">
                  <small className="text-muted">Last updated 8th September 2022</small>
                </p>
              </div>
            </div>
          </Row>
        </div>
      </ContentLayout>
    </>
  );
};
