import webcam_monitor from "../../../../assets/images/raspberrypienergymonitor/webcam_monitor.png";
import webcam_capture from "../../../../assets/images/raspberrypienergymonitor/webcam_capture.png";
import sqlite_capture from "../../../../assets/images/raspberrypienergymonitor/sqlite_capture.png";
import exported_to_googlesheets from "../../../../assets/images/raspberrypienergymonitor/exported_to_googlesheets.png";
import dashboard from "../../../../assets/images/raspberrypienergymonitor/dashboard.png";
import { ContentLayout } from "../../layout/ContentLayout";
export const PiEnergyMonitor = () => {
  return (
    <>
      <ContentLayout useBackground={true}>
        <h1>Raspberry Pi Energy Monitor</h1>
        <h2>Why I started this project</h2>
        <p className="lead">
          With the recent energy price rises here in the UK I started paying closer attention to how much I am paying
          for gas and electric. According to Ofgem, the average household in the UK has 2.4 people living in it and uses
          2,900 kWh, or an average 3-bed house uses 3,000 kWh. I was pretty shocked to find out I am using an average of
          6,000kWh per year. I then became a little bit obsessed with finding out why my household was using twice the
          national average. I live in a 3 bedroom house with my wife and two teenage kids.
        </p>
        <p>
          I had a few questions I needed to find out -
          <ul>
            <li>Was my meter giving incorrect readings?</li>
            <li>Did I have a high consumption appliances - if so which ones?</li>
            <li>Was my next door neighbour stealing my electric?</li>
            <li>Should I get a smart meter?</li>
          </ul>
        </p>
        <p>
          I eventually bought a plug in monitor that I could use to measure energy usage from a single plug. I spent
          weeks monitoring all my appliances and meticulously recorded usage on a Google Sheet. I couldnt see anything
          obvious, but did implement a few changes, for example,
          <ul>
            <li>my old fridge with a few beers in was costing £150 per year, this was scrapped</li>
            <li>
              my Synology DiskStation NAS on 24 hours a day used to stream a few old tv shows every now and then was
              costing £50 per year, this was shutdown
            </li>
            <li>
              my expensive heat pump dryer that takes about 6 hours to dry anything, used about the same energy as my
              cheap vented dryer that takes about 1 1/2 hours - still need to do a few more checks on this
            </li>
          </ul>
        </p>
        <p>I was no closer to understanding why my energy usage was so high.</p>
        <p>
          I contemplated getting a smart meter, but I am still not sure. There are a lot of mixed reviews some good,
          some really, really bad. I am not ready to commit on this one yet.
        </p>
        <p>
          I then wondered about getting an energy monitor that monitors usage at the circult panel so it can see the
          consumption of everything. Again, mixed reviews - Amazon average 4 starts, but top reviews being:
          <ul>
            <li>
              <em>Beware, Cloud only service</em>
            </li>
            <li>
              <em>If in the uk - take note</em>
            </li>
            <li>
              <em>Comes with everything you need - it just works very badly</em>
            </li>
          </ul>
        </p>
        <p>Also, with costs starting at £100 it was a risk.</p>
        <p>
          I noticed that some of the devices measured a pulse on the meter and being a developer I started to wonder
          whether I could build my own. Why pay £100 on a potential door stop when you can spend the next few months
          rolling your own and learning a whole lot along the way?
        </p>
        <p>This is where the idea came from to start this project.</p>
        <h2 id="what-is-the-raspberry-pi-energy-monitor-">What is the Raspberry Pi Energy Monitor?</h2>
        <p>
          I wanted to understand whether <strong>I use a webcam to record the blinks on the electric meter?</strong>
        </p>
        <p>After a few weeks of messing around I finally have a basic working prototype. The prototype consists of -</p>
        <ul>
          <li>
            <p>a Rasberry Pi</p>
          </li>
          <li>
            <p>a standard webcam.</p>
          </li>
          <li>
            <p>two nodejs express microservices to save the capture to sqlite database</p>
          </li>
          <li>
            <p>a simple javascript website to capture the webcam output and send it to the microservices</p>
          </li>
          <li>
            <p>and a nodejs/pupeteer command line app to start the capture from command line</p>
          </li>
        </ul>
        <p>Once I confirmed the prototype I built a simple dashboard to display the data.</p>
        <div className="text-center">
          <div className="text-center">
            <figure className="figure">
              <img src={dashboard} alt="React Dashboard" className="rounded mx-auto d-block img-fluid" />
              <figcaption className="figure-caption text-right">React Dashboard</figcaption>
            </figure>
          </div>

          <figure className="figure">
            <img src={webcam_monitor} alt="Webcam pointing at meter" className="rounded mx-auto d-block img-fluid" />
            <figcaption className="figure-caption text-right">Webcam pointing at meter</figcaption>
          </figure>
          <figure className="figure">
            <img
              src={webcam_capture}
              alt="View from the automated capture program"
              className="rounded mx-auto d-block img-fluid"
            />
            <figcaption className="figure-caption text-right">View from the automated capture program</figcaption>
          </figure>
          <figure className="figure">
            <img src={sqlite_capture} alt="Raw SQL data" className="rounded mx-auto d-block img-fluid" />
            <figcaption className="figure-caption text-right">Raw SQL data</figcaption>
          </figure>
          <figure className="figure">
            <img
              src={exported_to_googlesheets}
              alt="Exported data in google sheets"
              className="rounded mx-auto d-block img-fluid"
            />
            <figcaption className="figure-caption text-right">Exported data in google sheets</figcaption>
          </figure>
        </div>
      </ContentLayout>
    </>
  );
};
