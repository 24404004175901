import banner from "../../../../assets/images/raspberrypienergymonitor/banner2.png";
import { ContentLayout } from "../../layout/ContentLayout";

export const PiEnergyMonitor_Overview = () => {
  return (
    <>
      <ContentLayout>
        <div className="jumbotron">
          <h1 className="display-4">Pi Energy Monitor</h1>
          <p className="lead">
            The Raspberry Pi Energy Monitor is a project that I am currently working on. It is a system that uses a
            standard webcam and a Raspberry Pi 4 to monitor the energy usage of an household.
          </p>
          <hr className="my-4" />
          <p>
            The webcam monitors the blinking light on the electricity meter and the Raspberry Pi 4 uses a number of
            small nodejs scripts and a sqlite database to record the energy usage.
          </p>
          <p>A React website provides a dashboard interace to show a summary of the energy usage.</p>
          <img src={banner} alt="Webcam monitor screenshot" className="img-fluid mb-3"></img>
          <p className="lead">
            <a className="btn btn-primary btn-lg" href="/pi_energy_monitor" role="button">
              More Details
            </a>
          </p>
        </div>
      </ContentLayout>
    </>
  );
};
