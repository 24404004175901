import { NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Navbar bg="primary" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">Paul Diggle</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/about">
                About
              </Link>
              <Link className="nav-link" to="/cv">
                CV
              </Link>
              <Link className="nav-link" to="/now">
                Now
              </Link>
              <NavDropdown title="Projects" id="basic-nav-dropdown">
                <Link className="dropdown-item" to="/pi_energy_monitor_overview">
                  Raspberry Pi Energy Monitor
                </Link>
                <Link className="dropdown-item" to="/pixel_overview">
                  Pixel Dash - Pixel based animation tool
                </Link>

                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/HackManchester2019_Overview">
                  Hack Manchester 2019
                </Link>
                <NavDropdown.Item href="#action/3.3">Hack Manchester 2018</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
