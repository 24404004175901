import { ContentLayout } from "../layout/ContentLayout";

const Now = () => {
  return (
    <>
      <ContentLayout useBackground={true}>
        <h1>Now</h1>
        <blockquote>
          <p>
            This is my 'now' page, what I'm doing right now. It's inspired by Derek Sivers'{" "}
            <u>
              <a href="https://sive.rs/nowff">now page</a>
            </u>{" "}
            and{" "}
            <u>
              <a href="https://nownownow.com/about">now now now</a>
            </u>{" "}
            movement.
          </p>
        </blockquote>

        <h3>End of summer tidy up</h3>
        <p>
          Painted the hall and stairs and landing. Found out how useful decorators caulk is for filling in cracks. I
          will never use filler again.
        </p>
        <h3>20 year wedding anniversary</h3>
        <p>
          20 years since I married my lovely wife Laura. Starting to think about what we want to do for the next 20.
        </p>
        <h3>Worried about my energy bills</h3>
        <p>
          When I realised I was using double the national average electricity I documented the kwh usage of all my
          appliances in a spreadsheet. Typical developer style I ended building a usage monitor using a raspberry pi,
          webcam and nodejs.
        </p>
        <p>I am also researching solar panels and battery storage.</p>
        <small className="text-muted">Last updated 8th September 2022</small>
      </ContentLayout>
    </>
  );
};

export default Now;
