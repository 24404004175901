import { Col, Container, Row } from "react-bootstrap";

type Props = {
  children: React.ReactNode;
  useBackground?: boolean;
};
export const ContentLayout = (props: Props) => {
  return (
    <>
      <Container className={props.useBackground ? "containerbackground mt-3" : ""}>
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </>
  );
};
