import { Container, Col, Row, Table, Button } from "react-bootstrap";
import "./CV.css";

export const CV = () => {
  return (
    <>
      <Container>
        <p className="text-right">
          <a href="https://www.diggs.co.uk/cv/Paul Diggle CV - Sept 2022.pdf" target={"_blank"}>
            Download CV
          </a>
        </p>

        <div className="paper">
          <div className="cv p-sm-5 p-2">
            <h1>Paul Diggle - CV</h1>
            <Row>
              <h2 className="cv-title">SUMMARY</h2>
              <p>
                I am a team leader/senior developer with 22 years technical experience including 18 years in software
                development. I have a BSc (Hons) in Computer Science and an MSc in Distributed Interactive Systems at
                Lancaster University.
              </p>
              <p>
                Currently, I am a senior full stack developer in the AO Last Mile team - tasked with delivering
                innovation and stability for the AO driver fleet and customer order tracking system. The team is fully
                autonomous, with a “you build it you run it” model. I am highly experienced in building backend systems
                in C#, NodeJS and AWS and frontend systems using React, HTML and Javascript.
              </p>
              <p>
                My team members find me an approachable and supportive colleague, and I enjoy sharing my knowledge and
                expertise responding to technical queries and project requirement issues where necessary.
              </p>
            </Row>
            <Row>
              <h2 className="cv-title">TECHNICAL COMPETENCY SUMMARY</h2>
              <Col>
                <Table size="sm" striped="columns" responsive>
                  <tbody>
                    <tr>
                      <td>Programming Languages</td>
                      <td>TypeScript/Javascript, C#, Objective C, TDD</td>
                    </tr>
                    <tr>
                      <td>Developer Type</td>
                      <td>
                        Full Stack Developer - Frontend (React/HTML/Javascript) ⇒ Backend (Nodejs, C#,) ⇒ Mobile
                        (Objective C, Progressive Web Apps)
                      </td>
                    </tr>
                    <tr>
                      <td>Development style</td>
                      <td>Prefer agile methodology using Scrum, Clean Code, SOLID, mobile first website dev</td>
                    </tr>
                    <tr>
                      <td>Cloud</td>
                      <td>AWS, API Gateway, Lambda, DynamoDB, S3</td>
                    </tr>
                    <tr>
                      <td>Databases</td>
                      <td>SQL Server, SQLite, DynamoDB, MongoDB</td>
                    </tr>
                    <tr>
                      <td>Infrastructure as Code</td>
                      <td>Serverless</td>
                    </tr>
                    <tr>
                      <td>CI/CD</td>
                      <td>Bitbucket Pipelines, TeamCity, Octopus</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="cv-title">EXPERIENCE</h2>
                <div className="cv-item">
                  <h3>AO.com, Bolton - Development Team Leader/Senior Software Developer</h3>
                  <h5>MARCH 2017 - PRESENT</h5> <span className="ms-1">(5 years)</span>
                  <p>
                    <em>
                      AO.com is an online electrical retailer with a range of cross functional product and delivery
                      teams.
                    </em>
                  </p>
                  <h4>Software Development Experience (February 2022 - Present)</h4>
                  <ul>
                    <li>
                      Past 12 months, I requested to step back from the team lead role to focus on my development
                      skills.
                    </li>
                    <li>
                      Part of an agile team responsible for providing mobile software for the driver fleet and the
                      public facing Track Your Order website.
                    </li>
                    <li>
                      Experience building C# and NodeJS/TypeScript event driven services using AWS components, including
                      Lambda, SNS/SQS, API Gateway, DynamoDB.
                    </li>
                    <li>Experience building greenfield React websites.</li>
                    <li>Supporting legacy systems.</li>
                    <li>
                      Currently integrating a new fleet routing system. Building a system to push driver GPS positions
                      to provide constant route updates. These feed into the Track Your Order system to provide live
                      ETAs.
                    </li>
                    <li>
                      Continuing with the previous technical lead role working directly with stakeholders and developers
                      to identify requirements and specify designs.
                    </li>
                    <li>Continuing with informal leadership duties of the other developers in the team.</li>
                  </ul>
                  <h4>Team Leader Experience (March 2017 - February 2022)</h4>
                  <ul>
                    <li>Responsible for the development team and accountable for the delivery of quality software.</li>
                    <li>
                      Technical lead working directly with stakeholders and developers to identify requirements and
                      specify designs.
                    </li>
                    <li>Support and encourage the team in their duties and career progression.</li>
                    <li>
                      Managerial duties including recruitment, supervision, line management, staffing levels and
                      disciplinary proceedings.
                    </li>
                    <li>Quarterly Product Review presentations to senior leadership team.</li>
                  </ul>
                </div>
                <div className="cv-item">
                  <h4>GPC Computer Software Ltd, Cheadle - App Team Leader/Lead iPhone Developer</h4>
                  <h5>APRIL 2013 - MARCH 2017</h5>
                  <span className="ms-1">(5 years)</span>
                  <p>
                    <em>
                      GPC Computer Software Ltd provide Taxi Booking and dispatch systems with a 60% market share and
                      over 1500 customers worldwide.
                    </em>
                  </p>
                  <ul>
                    <li>
                      Part of a team responsible for the iPhone development and associated C# WCF web services/SQL
                      server.
                    </li>
                    <li>Mainly work with Objective C.</li>
                    <li>Provide support and direction to the C# WCF web service developer.</li>
                    <li>Provide support and direction to the Senior Android developer.</li>
                    <li>Interviewed candidates for iPhone and Android positions.</li>
                    <li>
                      Project Link -{" "}
                      <a target={"_blank"} href="http://streetcarsmanchester.co.uk/apps/">
                        http://streetcarsmanchester.co.uk/apps/
                      </a>{" "}
                      (one example of a Premium App version 3 customer, hundreds have been released to date)
                    </li>
                  </ul>
                </div>
                <div className="cv-item">
                  <h4>DCML, Bredbury - Senior C# Developer</h4>
                  <h5>APRIL 2007 - APRIL 2013</h5>
                  <span className="ms-1">(7 years)</span>
                  <p>
                    <em>DCML provides software solutions to the car industry.</em>
                  </p>
                  <ul>
                    <li>Design, develop, and maintain their software portfolio.</li>
                    <li>Mainly worked on C# Web Forms, HTML, Javascript and SQL server.</li>
                    <li>Learned Objective C and developed DCMLs first mobile app.</li>
                  </ul>
                </div>
                <div className="cv-item">
                  <h4>Ryder Systems, Blackburn - C# Web Developer</h4>
                  <h5>NOVEMBER 2004 - APRIL 2007</h5>
                  <span className="ms-1">(4 years)</span>
                  <p>
                    <em>
                      Ryder Systems provides software solutions to the telecoms industry with clients including Orange,
                      Vodafone and BT.
                    </em>
                  </p>
                  <ul>
                    <li>Mainly worked on VB.NET web forms and SQL Server.</li>
                  </ul>
                </div>
                <div className="cv-item">
                  <h4>University Of Central Lancashire, Preston - Network Specialist</h4>
                  <h5>NOVEMBER 2004 - APRIL 2007</h5>
                  <span className="yearSpan ms-1">(1 year)</span>
                  <p>
                    <em>NOVEMBER 2003 - NOVEMBER 2004</em>
                  </p>
                  <ul>
                    <li>Designed and implemented the University TCP/IP network.</li>
                    <li>3rd Line Network support.</li>
                    <li>Implemented SecureId authentication on Checkpoint Firewall.</li>
                  </ul>
                </div>
                <div className="cv-item">
                  <h4>Lancaster University, Lancaster - Network Specialist</h4>
                  <h5>NOVEMBER 2004 - APRIL 2007</h5>
                  <span className="ms-1">(1 year_</span>
                  <p>
                    <em>SEPTEMBER 2002 - NOVEMBER 2003</em>
                  </p>
                  <ul>
                    <li>
                      Responsible for major design aspects of the University Network and the Cumbria and Lancashire
                      metropolitan area network.
                    </li>
                    <li>Physically installed multiple point to point WIFI links across Cumbria.</li>
                    <li>Represented the University at key UK wide SuperJANET upgrade meetings.</li>
                  </ul>
                </div>

                <div className="cv-item">
                  <h4>British Telecom, Ipswich- IP Network Specialist</h4>
                  <h5>OCTOBER 2000 - SEPTEMBER 2002</h5> <span className="ms-1">(2 years)</span>
                  <p>
                    <em>Adastral Park - Research Campus</em>
                  </p>
                  <ul>
                    <li>Tested and evaluated future Internet technologies for inclusion into the BT core networks.</li>
                    <li>Designing and building performance test suites to test Cisco's new QoS implementation.</li>
                    <li>Implemented SIP protocol using Visual C++.</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="cv-title">EDUCATION</h3>
                <div className="cv-item">
                  <ul>
                    <li>
                      Microsoft Professional Exam, Web Applications Development with Microsoft .NET Framework 4 -
                      SEPTEMBER 2011
                    </li>
                    <li>
                      Lancaster University, Lancaster - MSc Interactive and Distributed Systems - OCTOBER 1999 - OCTOBER
                      2000
                    </li>
                    <li>Lancaster University, Lancaster - BSc Computer Science - 2:1 SEPTEMBER 1996 - JULY 1999</li>
                    <li>Blackpool Sixth Form College, Blackpool - 3 A Levels - 1994 - 1996</li>
                    <li>Withins High School, Bolton - 8 GCSEs - 1989 - 1994.</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="cv-title">PROFESSIONAL COURSES</h3>
                <div className="cv-item">
                  <ul>
                    <li>Systems Design and Implementation Techniques - APRIL 2009</li>
                    <li>Object Oriented Analysis and Design with UML - APRIL 2007</li>
                    <li>Microsoft Course 2073B - Programming a MS SQL Server 2000 Database - NOVEMBER 2006</li>
                    <li>Upgrading ASP Skills to ASP.NET - Datrix Training, Leyland - NOVEMBER 2004</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="cv-title">AWARDS AND PROJECTS</h3>
                <div className="cv-item">
                  <ul>
                    <li>Hack Manchester 2019 Participant</li>
                    <li>Hack Manchester 2018 Winner</li>
                    <li>Personal Project - Energy consumption system using Raspberry Pi, Webcam and NodeJS/React</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};
